import { Suspense, lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import RootLayout from "../components/RootLayout";
import LoginPage from "../pages/Login";
import Loading from "../components/ui/loading/Loading";
import ErrorPage from "../pages/Error";

// * Pages importing
const HomePage = lazy(() => import("../pages/Home"));
const DocsPageBanka = lazy(() => import("../pages/Docs.banka.js"));
const DocsPagePapara = lazy(() => import("../pages/Docs.papara.js"));
const DocsProPageBanka = lazy(() => import("../pages/Docs.pro.banka.js"));
const DocsProPagePapara = lazy(() => import("../pages/Docs.pro.papara.js"));
const SuccessDepositPage = lazy(() =>
  import("../pages/operationPages/SuccessDeposit")
);
const SuccessWithdrawPage = lazy(() =>
  import("../pages/operationPages/SuccessWithdraw")
);
const RejectionWithdrawPage = lazy(() =>
  import("../pages/operationPages/RejectionWithdraw")
);
const RejectionDepositPage = lazy(() =>
  import("../pages/operationPages/RejectionDeposit")
);
const WaitingWithdrawPage = lazy(() =>
  import("../pages/operationPages/WaitingWithdraw")
);
const WaitingDepositPage = lazy(() =>
  import("../pages/operationPages/WaitingDeposit")
);
const WebsitesPage = lazy(() =>
  import("../pages/finacialTransaction/Websites")
);
const PoolSettingsPage = lazy(() =>
  import("../pages/finacialTransaction/PoolsSettings.js")
);

const LogPage = lazy(() => import("../pages/finacialTransaction/LogPage.js"));

const ConsensusPage = lazy(() =>
  import("../pages/finacialTransaction/Consensus")
);

const AllConsensusPage = lazy(() =>
  import("../pages/finacialTransaction/AllConsensus")
);

const ConsensusBySite = lazy(() =>
  import("../pages/finacialTransaction/ConsensusBySite")
);

const UsersPage = lazy(() => import("../pages/userSettings/Users"));
const UserCreatePage = lazy(() => import("../pages/userSettings/UserCreate"));
const PlayersPage = lazy(() => import("../pages/definitions/Players"));
const BankAccountsPagePapara = lazy(() =>
  import("../pages/definitions/BankAccounts.papara.js")
);
const BankAccountsPageBanka = lazy(() =>
  import("../pages/definitions/BankAccounts.banka.js")
);
const EditBankAccountPagePapara = lazy(() =>
  import("../pages/definitions/EditBankAccount.papara.js")
);
const EditBankAccountPageBanka = lazy(() =>
  import("../pages/definitions/EditBankAccount.banka.js")
);
const CashDeliveryPage = lazy(() => import("../pages/CashDeliveryPage"));
const ChangePasswordPage = lazy(() => import("../pages/ChangePassword.js"));

const profile = JSON.parse(localStorage.getItem("profile"));

let router;

const commonRoutes = [
  {
    path: "/login",
    element: <LoginPage />,
    action: ({ request }) =>
      import("../pages/Login").then((module) => module.action({ request })),
  },
  {
    path: "/docs",
    element:
      process.env.REACT_APP_PAPARA_ENABLED === "true" ? (
        <DocsPagePapara />
      ) : (
        <DocsPageBanka />
      ),
  },
  {
    path: "/pro-docs",
    element:
      process.env.REACT_APP_PAPARA_ENABLED === "true" ? (
        <DocsProPagePapara />
      ) : (
        <DocsProPageBanka />
      ),
  },
];

if (!profile) {
  router = createBrowserRouter([
    ...commonRoutes,
    {
      path: "/",
      element: <LoginPage />,
      action: ({ request }) =>
        import("../pages/Login").then((module) => module.action({ request })),
    },
  ]);
} else {
  // !! ilk item'ı sakın değiştirme :)
  let routerItems = [
    {
      path: "/",
      element: <RootLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<Loading />}>
              <HomePage />
            </Suspense>
          ),
          loader: ({ request }) =>
            import("../pages/Home").then((module) =>
              module.loader({ request })
            ),
        },
        {
          path: "success-deposit",
          permissionKey: "operationalPages",
          permissionPage: "successDeposit",
          element: (
            <Suspense fallback={<Loading />}>
              <SuccessDepositPage />
            </Suspense>
          ),
        },
        {
          path: "rejection-deposit",
          permissionKey: "operationalPages",
          permissionPage: "rejectionDeposit",
          element: (
            <Suspense fallback={<Loading />}>
              <RejectionDepositPage />
            </Suspense>
          ),
        },
        {
          path: "success-withdraw",
          permissionKey: "operationalPages",
          permissionPage: "successWithdraw",
          element: (
            <Suspense fallback={<Loading />}>
              <SuccessWithdrawPage />
            </Suspense>
          ),
        },
        {
          path: "rejection-withdraw",
          permissionKey: "operationalPages",
          permissionPage: "rejectionWithdraw",
          element: (
            <Suspense fallback={<Loading />}>
              <RejectionWithdrawPage />
            </Suspense>
          ),
          loader: ({ request }) =>
            import("../pages/operationPages/RejectionWithdraw").then((module) =>
              module.loader({ request })
            ),
        },
        {
          path: "waiting-deposit",
          permissionKey: "operationalPages",
          permissionPage: "waitingDeposit",
          element: (
            <Suspense fallback={<Loading />}>
              <WaitingDepositPage />
            </Suspense>
          ),
        },
        {
          path: "waiting-withdraw",
          permissionKey: "operationalPages",
          permissionPage: "waitingWithdraw",
          element: (
            <Suspense fallback={<Loading />}>
              <WaitingWithdrawPage />
            </Suspense>
          ),
          loader: ({ request }) =>
            import("../pages/operationPages/WaitingWithdraw").then((module) =>
              module.loader({ request })
            ),
        },
        {
          path: "logs",
          permissionKey: "financialPages",
          permissionPage: "logs",
          element: (
            <Suspense fallback={<Loading />}>
              <LogPage />
            </Suspense>
          ),
          loader: ({ request }) =>
            import("../pages/finacialTransaction/LogPage.js").then((module) =>
              module.loader({ request })
            ),
        },
        {
          path: "create-user",
          permissionKey: "definitionPages",
          permissionPage: "createUser",
          element: (
            <Suspense fallback={<Loading />}>
              <UserCreatePage />
            </Suspense>
          ),
        },
        {
          path: "edit-bank-account",
          permissionKey: "definitionPages",
          permissionPage: "bankAccounts",
          element: (
            <Suspense fallback={<Loading />}>
              {process.env.REACT_APP_PAPARA_ENABLED === "true" ? (
                <EditBankAccountPagePapara />
              ) : (
                <EditBankAccountPageBanka />
              )}
            </Suspense>
          ),
          loader: ({ request }) =>
            import(
              `../pages/definitions/EditBankAccount.${
                process.env.REACT_APP_PAPARA_ENABLED === "true"
                  ? "papara"
                  : "banka"
              }.js`
            ).then((module) => module.loader({ request })),
        },
        {
          path: "consensus",
          permissionKey: "financialPages",
          permissionPage: "consensus",
          element: (
            <Suspense fallback={<Loading />}>
              <ConsensusPage />
            </Suspense>
          ),
          loader: ({ request }) =>
            import("../pages/finacialTransaction/Consensus").then((module) =>
              module.loader({ request })
            ),
        },
        {
          path: "allconsensus",
          permissionKey: "financialPages",
          permissionPage: "allconsensus",
          element: (
            <Suspense fallback={<Loading />}>
              <AllConsensusPage />
            </Suspense>
          ),
          loader: ({ request }) =>
            import("../pages/finacialTransaction/AllConsensus").then((module) =>
              module.loader({ request })
            ),
        },

        {
          path: "consensusbysite",
          permissionKey: "financialPages",
          permissionPage: "consensusbysite",
          element: (
            <Suspense fallback={<Loading />}>
              <ConsensusBySite />
            </Suspense>
          ),
          loader: ({ request }) =>
            import("../pages/finacialTransaction/ConsensusBySite").then((module) =>
              module.loader({ request })
            ),
        },
        
        {
          path: "bank-accounts",
          permissionKey: "definitionPages",
          permissionPage: "bankAccounts",
          element: (
            <Suspense fallback={<Loading />}>
              {process.env.REACT_APP_PAPARA_ENABLED === "true" ? (
                <BankAccountsPagePapara />
              ) : (
                <BankAccountsPageBanka />
              )}
            </Suspense>
          ),
          loader: ({ request }) =>
            import(
              `../pages/definitions/BankAccounts.${
                process.env.REACT_APP_PAPARA_ENABLED === "true"
                  ? "papara"
                  : "banka"
              }.js`
            ).then((module) => module.loader({ request })),
        },
        {
          permissionKey: "definitionPages",
          permissionPage: "users",
          path: "users",
          element: (
            <Suspense fallback={<Loading />}>
              <UsersPage />
            </Suspense>
          ),
        },
        {
          path: "players",
          permissionKey: "definitionPages",
          permissionPage: "players",
          element: (
            <Suspense fallback={<Loading />}>
              <PlayersPage />
            </Suspense>
          ),
        },
        {
          path: "websites",
          permissionKey: "financialPages",
          permissionPage: "websites",
          element: (
            <Suspense fallback={<Loading />}>
              <WebsitesPage />
            </Suspense>
          ),
          loader: ({ request }) =>
            import("../pages/finacialTransaction/Websites").then((module) =>
              module.loader({ request })
            ),
        },
        {
          path: "pools",
          permissionKey: "financialPages",
          permissionPage: "pools",
          element: (
            <Suspense fallback={<Loading />}>
              <PoolSettingsPage />
            </Suspense>
          ),
          loader: ({ request }) =>
            import(`../pages/finacialTransaction/PoolsSettings.js`).then(
              (module) => module.loader({ request })
            ),
        },
        {
          path: "cash-delivery",
          permissionKey: "financialPages",
          permissionPage: "cashDelivery",
          element: (
            <Suspense fallback={<Loading />}>
              <CashDeliveryPage />
            </Suspense>
          ),
          loader: ({ request }) =>
            import("../pages/CashDelivery").then((module) =>
              module.loader({ request })
            ),
        },
        {
          path: "sifre-degistir",
          element: (
            <Suspense fallback={<Loading />}>
              <ChangePasswordPage />
            </Suspense>
          ),
        },
      ],
    },
    ...commonRoutes,
  ];

  routerItems[0].children = routerItems[0].children.filter((page) => {
    if (page?.permissionKey && page?.permissionPage) {
      if (!profile?.viewPermissions) {
        localStorage.clear();
        window.location.href = "/login";
      }
      return profile?.viewPermissions[page?.permissionKey][
        page?.permissionPage
      ];
    }
    return true;
  });

  router = createBrowserRouter(routerItems);
}

export { router };
